/** @jsx jsx */

import { jsx } from 'theme-ui';
import { useSpring, animated } from 'react-spring';
import { withBackground } from './Background';
import { toPercent } from './helpers';
import i4phoneleft from '../../images/i4phoneleft.png';
import i4phoneright from '../../images/i4phoneright.png';

const WIDTH = 356;
const HEIGHT = 546;

export const Phones = ({ isInView, animationWidth, animationHeight }) => {
  const from = { opacity: 0, y: 20 };
  const to = { opacity: 1, y: 0 };

  const { opacity, y } = useSpring({
    from,
    to: isInView ? to : from,
    delay: 400
  });

  return (
    <div
      sx={{
        width: toPercent(WIDTH / animationWidth),
        height: toPercent(HEIGHT / animationHeight),
        position: 'relative',
        left: toPercent(72 / animationWidth),
        top: toPercent(-23 / animationHeight),

        '.phoneleft, .phoneright': {
          position: 'absolute',
          backgroundSize: 'cover'
        },

        '.phoneleft': {
          backgroundImage: `url(${i4phoneleft})`,
          backgroundPosition: 'bottom left',
          width: toPercent(214 / WIDTH),
          height: toPercent(395 / HEIGHT),
          bottom: 0,
          left: 0
        },

        '.phoneright': {
          backgroundImage: `url(${i4phoneright})`,
          backgroundPosition: 'top right',
          width: toPercent(208 / WIDTH),
          height: toPercent(381 / HEIGHT),
          top: 0,
          right: 0
        }
      }}
    >
      <animated.div
        className="phoneleft"
        style={{
          opacity,
          transform: y.interpolate(y => `translateY(${y}px)`)
        }}
      />
      <animated.div
        className="phoneright"
        style={{
          opacity,
          transform: y.interpolate(y => `translateY(-${y}px)`)
        }}
      />
    </div>
  );
};

export default withBackground(Phones, 200);
