/** @jsx jsx */

import { jsx } from 'theme-ui';
import { useState, useEffect } from 'react';
import { useSpring, useTransition, animated } from 'react-spring';
import sample from 'lodash/sample';
import uniqueId from 'lodash/uniqueId';
import shuffle from 'lodash/shuffle';
import { withBackground } from './Background';

const TRANSACTIONS = [
  {
    emoji: '👟',
    title: 'Nike',
    description: 'Shopping',
    amount: () => -sample([95, 130, 160])
  },
  {
    emoji: '👕',
    title: 'Adidas',
    description: 'Shopping',
    amount: () => -sample([30, 45, 60])
  },
  {
    emoji: '🎧',
    title: 'Spotify',
    description: 'Divertissement',
    amount: () => -4.99
  },
  {
    emoji: '📺',
    title: 'Netflix',
    description: 'Divertissement',
    amount: () => -7.99
  },
  {
    emoji: '☕️',
    title: 'Starbucks',
    description: 'Nourriture',
    amount: () => -sample([3.85, 4.95, 5.45, 5.75])
  },
  {
    emoji: '🍟',
    title: "McDonald's",
    description: 'Nourriture',
    amount: () => -sample([5.9, 7.5, 15])
  },
  {
    emoji: '💶',
    title: 'Maman',
    description: 'Revenu',
    amount: () => sample([50, 100, 150, 200])
  },
  {
    emoji: '🎂',
    title: 'Anniversaire',
    description: 'Revenu',
    amount: () => sample([100, 200, 300])
  },
  {
    emoji: '🎟',
    title: 'Cinéma',
    description: 'Divertissement',
    amount: () => -7.8
  }
];

let _transactions = [];
const createTransaction = () => {
  if (!_transactions.length) {
    _transactions = shuffle(TRANSACTIONS);
  }

  const t = _transactions.pop();
  return {
    ...t,
    id: uniqueId('tr'),
    amount: t.amount().toFixed(2)
  };
};

const INITIAL_TRANSACTIONS = [createTransaction(), createTransaction()];

const Transaction = ({ emoji, title, description, amount }) => {
  const isPositive = amount > 0;

  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 300,
        padding: 3,
        background: '#fff',
        borderRadius: 'medium',
        boxShadow: 'small',
        fontSize: 1,

        '.emoji': {
          width: 44,
          height: 44,
          fontSize: 24,
          textAlign: 'center',
          lineHeight: '44px',
          bg: 'cream100',
          borderRadius: 'xlarge',
          flexGrow: 0
        },

        '.amount': {
          width: 70,
          height: 28,
          lineHeight: '28px',
          flexGrow: 0,
          textAlign: 'center',
          borderRadius: 'xlarge',
          fontWeight: 'heading',

          '&.negative': {
            bg: 'cream100'
          },

          '&.positive': {
            bg: 'rgba(39, 223, 182, 0.1);',
            color: '#27DFB6'
          }
        },

        '.content': {
          flexGrow: 2,
          textAlign: 'left',
          marginRight: 3,
          marginLeft: 3
        },

        '.title': {
          color: 'black100'
        }
      }}
    >
      <span className="emoji" role="img" aria-label={title}>
        {emoji}
      </span>

      <div className="content">
        <p className="title">{title}</p>
        <p className="description">{description}</p>
      </div>

      {isPositive ? (
        <div className="amount positive">+{amount}€</div>
      ) : (
        <div className="amount negative">{amount}€</div>
      )}
    </div>
  );
};

const Transactions = ({ isInView }) => {
  const [transactions, setTransactions] = useState(INITIAL_TRANSACTIONS);

  useEffect(() => {
    const interval = setInterval(() => {
      setTransactions(transactions => [transactions[1], createTransaction()]);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const { opacity } = useSpring({
    from: { opacity: 0 },
    to: { opacity: isInView ? 1 : 0 },
    delay: 300
  });

  const transitions = useTransition(transactions, t => t.id, {
    from: { opacity: 0, height: 0, transform: 'scale(0.8) translateY(10px)' },
    enter: {
      opacity: 1,
      height: 76 + 16,
      transform: 'scale(1) translateY(0)'
    },
    leave: {
      opacity: 0,
      height: 0,
      transform: 'scale(1.2) translateY(-76px)'
    }
  });

  return (
    <div
      css={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <animated.div sx={{ mt: 3 }} style={{ opacity }}>
        {transitions.map(({ item, props, key }) => (
          <animated.div key={key} style={props}>
            <Transaction {...item} />
          </animated.div>
        ))}
      </animated.div>
    </div>
  );
};

export default withBackground(Transactions, 150);
