/** @jsx jsx */

import { Box, jsx } from 'theme-ui';
import { useState, useEffect } from 'react';

const loadImage = imageSrc => {
  return new Promise(resolve => {
    const imageEl = new Image();

    imageEl.onload = resolve;

    imageEl.src = imageSrc;
  });
};

const useLazyImage = imageSrc => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!imageSrc) return;

    setIsLoading(true);
    loadImage(imageSrc).then(() => setIsLoading(false));
  }, [imageSrc]);

  return {
    isLoading,
    src: imageSrc
  };
};

const LazyBackground = ({
  backgroundSrc,
  backgroundOpacity = 1,
  gradientOpacity = 0.4,
  backgroundPosition = 'top center',
  children
}) => {
  const { src, isLoading } = useLazyImage(backgroundSrc);

  return (
    <Box
      sx={{
        backgroundColor: '#000',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 10,
          width: '100%',
          height: '100%',
          opacity: isLoading ? 0 : backgroundOpacity,
          backgroundImage: !isLoading && `url(${src})`,
          backgroundPosition,
          backgroundSize: 'cover',
          transition: 'opacity 200ms ease-in'
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 20,
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(360deg, rgba(0, 0, 0, ${gradientOpacity}) 0%, rgba(0, 0, 0, 0) 50%);`
        }}
      />

      <Box
        sx={{
          position: 'relative',
          zIndex: 30
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default LazyBackground;
