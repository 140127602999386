/** @jsx jsx */

import { jsx, Styled, Box, Flex, Text } from 'theme-ui';
import React from 'react';
import { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { useInView } from 'react-intersection-observer';
import PhoneCard from '../components/animations/PhoneCard';
import Cards from '../components/animations/Cards';
import Transactions from '../components/animations/Transactions';
import Phones from '../components/animations/Phones';
import Referral from '../components/animations/Referral';
import Layout from '../components/Layout';
import StoreButtons from '../components/StoreButtons';
import LazyBackground from '../components/LazyBackground';
import Reviews from '../components/Reviews';
import { Container, Feature, Divider, CONTAINER_SPACE } from '../components/ui';
import analytics from '../lib/analytics';
import ellie from '../images/ellie.jpg';
import kash from '../images/kash.png';
import play from '../images/play.svg';

const FEATURE_ANIMATIONS = [Cards, Transactions, Phones, Referral];

const IOS_REGEXP = /ios|iphone|ipad|ipod/i;
const ANDROID_REGEXP = /android/i;

const isBrowser = typeof window !== 'undefined';

const usePlatform = () => {
  const [{ isIos, isAndroid }, setPlatform] = useState({});
  useEffect(() => {
    const ua = typeof navigator !== 'undefined' ? navigator.userAgent : '';

    const isIos = IOS_REGEXP.test(ua);
    const isAndroid = ANDROID_REGEXP.test(ua);

    setPlatform({ isIos, isAndroid });
  }, []);

  return { isBrowser, isIos, isAndroid };
};

const useSticky = () => {
  const [ref, isInView] = useInView();
  const [isSticky, setIsSticky] = useState();
  useEffect(() => {
    if (!isBrowser) return;
    if (isSticky == null && !isInView) return;

    setIsSticky(!isInView);
  }, [isInView, isSticky]);

  return [ref, isSticky];
};

const CIRCLE_DIAMETER = 26;
const CIRCLE_RADIUS = CIRCLE_DIAMETER / 2;
const COLORS = ['cyan100', 'purple100'];

const PLAY_BUTTON_DIAMETER = [64, 76];

const Index = ({ data: { content } }) => {
  const platform = usePlatform();
  const [ref, isSticky] = useSticky();

  return (
    <React.Fragment>
      <HelmetDatoCms seo={content.seoMetaTags} />

      <Layout sticky={isSticky}>
        <LazyBackground
          backgroundSrc={ellie}
          backgroundOpacity={1}
          gradientOpacity={0.3}
        >
          <Container
            ref={ref}
            sx={{
              mb: CONTAINER_SPACE,
              height: 680
            }}
          >
            <Flex
              sx={{
                px: 3,
                position: 'relative',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={content.videoUrl}
                onClick={() => analytics.track('PLAY_VIDEO_BUTTON_CLICK')}
                sx={{
                  display: 'block',
                  bg: 'white100',
                  width: PLAY_BUTTON_DIAMETER,
                  height: PLAY_BUTTON_DIAMETER,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  borderRadius: 'xlarge',
                  boxShadow: 'large',

                  img: {
                    display: 'block',
                    width: '100%',
                    height: '100%'
                  }
                }}
              >
                <img src={play} alt="Play" />
              </a>

              <Flex
                sx={{
                  flexDirection: ['column', 'row'],
                  alignSelf: 'flex-end',
                  alignItems: ['center', 'flex-end'],
                  justifyContent: 'space-between',
                  mt: 'auto',
                  pb: 7,
                  width: '100%'
                }}
              >
                <Styled.h1
                  sx={{
                    fontSize: [4, 5],
                    textAlign: ['center', 'left'],
                    color: 'white100',
                    fontWeight: 'bold',
                    mb: [6, 0],
                    textShadow: 'heading'
                  }}
                  dangerouslySetInnerHTML={{ __html: content.title }}
                />

                <StoreButtons variant="black" {...platform} />
              </Flex>
            </Flex>
          </Container>
        </LazyBackground>

        <Feature mb={CONTAINER_SPACE}>
          <>
            <Styled.h2 sx={{ mb: 4 }}>{content.mainTitle}</Styled.h2>

            <Box
              sx={{
                mb: 4,

                p: {
                  mb: 2
                }
              }}
              dangerouslySetInnerHTML={{
                __html: content.mainDescriptionNode.childMarkdownRemark.html
              }}
            />

            <Flex
              sx={{
                textAlign: 'center',
                color: 'black100',
                fontSize: 1,

                '.emoji': {
                  fontSize: 40
                }
              }}
            >
              {content.highlights.map((highlight, i) => {
                return (
                  <Box key={`highlight-${i}`} sx={{ width: '50%' }}>
                    <span className="emoji">{highlight.emoji}</span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: highlight.description
                      }}
                    />
                  </Box>
                );
              })}
            </Flex>
          </>

          <PhoneCard />
        </Feature>

        <Container mb={CONTAINER_SPACE}>
          <Text
            sx={{
              fontSize: '12px',
              textAlign: 'center',
              textTransform: 'uppercase',
              letterSpacing: 2,
              mb: 4
            }}
          >
            {content.pressTitle}
          </Text>

          <Flex
            sx={{
              mx: 4,
              justifyContent: 'center',
              alignItems: 'center',

              img: {
                maxWidth: 200
              },

              'img + img': {
                ml: [4, 6]
              }
            }}
          >
            {content.pressLinks.map(link => {
              return <Box as="img" src={link.logo.url} alt={link.title} />;
            })}
          </Flex>
        </Container>

        {content.features.map((feature, i) => {
          const Animation = FEATURE_ANIMATIONS[i] || 'h1';
          const color = COLORS[i % COLORS.length];

          const items = feature.items.split('\n').filter(v => !!v);

          return (
            <Feature key={`feature-${i}`} reverse={i % 2} mb={CONTAINER_SPACE}>
              <>
                <Styled.h2 sx={{ mb: 4 }}>{feature.title}</Styled.h2>

                <Divider sx={{ mb: 4 }} />

                <ul
                  sx={theme => {
                    const circleSpace = CIRCLE_DIAMETER + theme.space[3];

                    return {
                      listStyle: 'none',
                      pl: '0',

                      li: {
                        position: 'relative',
                        ml: circleSpace,
                        mb: 3,

                        svg: {
                          display: 'block',
                          width: CIRCLE_DIAMETER,
                          height: CIRCLE_DIAMETER,
                          position: 'absolute',
                          top: 0,
                          left: -circleSpace
                        }
                      }
                    };
                  }}
                >
                  {items.map((item, i) => {
                    return (
                      <li key={`item-${i}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={[
                            0,
                            0,
                            CIRCLE_DIAMETER,
                            CIRCLE_DIAMETER
                          ].join(' ')}
                        >
                          <circle
                            r={CIRCLE_RADIUS}
                            cx={CIRCLE_RADIUS}
                            cy={CIRCLE_RADIUS}
                            sx={{
                              fill: color,
                              opacity: 0.2
                            }}
                          />
                          <path
                            d="M9.14983464,11.580549 C8.67511423,11.050103 7.88595473,11.0293793 7.38719756,11.5342614 C6.88844039,12.0391434 6.86895475,12.8784426 7.34367515,13.4088886 L11.0839327,17.5882003 C11.5545183,18.1140261 12.3351874,18.1396509 12.8353666,17.6456895 L18.6015963,10.2976303 C19.1061703,9.79932873 19.1353873,8.96034897 18.6668543,8.42371654 C18.1983212,7.8870841 17.4094621,7.85601078 16.904888,8.35431232 L12.4252814,13.0799032 C12.2029872,13.3144041 11.8445312,13.3557362 11.5747013,13.1779802 L9.14983464,11.580549 Z"
                            sx={{
                              fill: color
                            }}
                          />
                        </svg>
                        <Styled.p>{item}</Styled.p>
                      </li>
                    );
                  })}
                </ul>
              </>

              <Animation />
            </Feature>
          );
        })}

        <Container
          sx={{
            mb: CONTAINER_SPACE,
            overflow: 'hidden'
          }}
        >
          <Styled.h2 sx={{ mb: 5, textAlign: 'center' }}>
            {content.reviewsTitle}
          </Styled.h2>

          <Reviews reviews={content.reviews} />
        </Container>

        <Container mb={CONTAINER_SPACE}>
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: ['center', 'flex-start'],
              mx: 3,
              borderRadius: 'large',
              overflow: ['hidden', 'visible'],
              boxShadow: 'small',
              position: 'relative',
              background:
                'linear-gradient(109.32deg, #7800F0 0%, #9850FF 100%)',

              '&:after': {
                content: '" "',
                display: 'block',
                position: [undefined, 'absolute'],
                zIndex: 500,
                bottom: 0,
                right: 0,
                height: 400,
                width: 571,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${kash})`,
                backgroundPosition: 'bottom center'
              }
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                px: 8,
                pt: 8,
                pb: [0, 8]
              }}
            >
              <Flex
                sx={{
                  flexDirection: 'column',
                  alignItems: ['center', 'flex-start'],
                  textAlign: ['center', 'left']
                }}
              >
                <Styled.h2
                  sx={{
                    width: ['auto', 380],
                    color: 'white100',
                    mb: 6
                  }}
                >
                  {content.downloadTitle}
                </Styled.h2>

                <StoreButtons variant="white" {...platform} />
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default Index;

export const pageQuery = graphql`
  query query {
    content: datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      videoUrl
      mainTitle
      mainDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
      highlights {
        emoji
        description
      }
      pressTitle
      pressLinks {
        id
        url
        title
        logo {
          url
        }
      }
      features {
        title
        items
      }
      reviewsTitle
      reviews {
        author
        date
        id
        description
        source
      }
      downloadTitle
    }
  }
`;
