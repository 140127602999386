export const toPercent = f => `${f * 100}%`;

export const toRadians = degrees => (degrees * Math.PI) / 180;

export const rotate = (r, degrees, animationWidth, animationHeight) => {
  const angle = toRadians(degrees);

  const x = r * Math.cos(angle) + animationWidth / 2;
  const y = r * Math.sin(angle) + animationHeight / 2;

  return { x, y };
};
