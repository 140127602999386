/** @jsx jsx */

import { jsx, Box, Flex, Text, css as themeUiCss, useThemeUI } from 'theme-ui';
import { ClassNames } from '@emotion/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const ITEM_WIDTH = 380;

const START_SIZE = 20;

const SOURCE_IMAGES = {
  instagram: require('../images/reviewinstagram.svg'),
  appStore: require('../images/reviewappstore.svg'),
  playStore: require('../images/reviewplaystore.svg'),
  email: require('../images/reviewemail.svg'),
  twitter: require('../images/reviewtwitter.svg')
};
const SOURCE_SIZE = 56;

const Star = props => {
  const { theme } = useThemeUI();

  return (
    <Box
      as="svg"
      width={START_SIZE}
      height={START_SIZE}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2452 7.27268H12.8179L10.7997 0.594541C10.6852 0.239089 10.3625 0 9.99882 0C9.63518 0 9.31245 0.239089 9.19791 0.594541L7.0979 7.27268H0.786972C-0.133031 7.27268 -0.133031 8.18176 0.213334 8.52903L5.37789 12.3963L3.28698 18.8553C3.17152 19.2126 3.29243 19.6053 3.58607 19.8299C3.88153 20.0535 4.28153 20.0571 4.5788 19.838L9.99882 15.8517L15.4188 19.838C15.5652 19.9453 15.7379 20 15.9088 20C16.0852 20 16.2625 19.9435 16.4116 19.8299C16.7052 19.6053 16.8261 19.2126 16.7107 18.8553L14.6197 12.3963L19.7834 8.52903C20.1316 8.18176 20.1316 7.27268 19.2452 7.27268Z"
        fill={theme.colors.purpleAlt100}
      />
    </Box>
  );
};

const Review = ({ description, author, source, date }) => {
  const [year, month, day] = date.split('-');
  const formattedDate = `${day}/${month}/${year}`;
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        borderRadius: 'large',
        boxShadow: '0px 0px 40px #F2EAFF',
        mx: 2,
        p: 4,
        bg: 'white100'
      }}
    >
      <Box
        sx={{
          flexGrow: 0,
          mb: 3,

          'svg + svg': {
            ml: 2
          }
        }}
      >
        <Star />
        <Star />
        <Star />
        <Star />
        <Star />
      </Box>

      <Text
        sx={{ flexGrow: 1, color: 'black100', height: 200, overflow: 'hidden' }}
      >
        {description}
      </Text>

      <Flex sx={{ flexGrow: 0, alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Text
            sx={{
              fontSize: 3,
              fontWeight: 'bold',
              color: 'black100'
            }}
          >
            {author}
          </Text>

          <Text sx={{ fontSize: 1 }}>{formattedDate}</Text>
        </Box>

        <Box
          as="img"
          src={SOURCE_IMAGES[source]}
          sx={{
            width: SOURCE_SIZE,
            height: SOURCE_SIZE,
            borderRadius: 'xlarge'
          }}
        />
      </Flex>
    </Flex>
  );
};

const Reviews = ({ reviews }) => {
  const { theme } = useThemeUI();

  const desktopWidth = (ITEM_WIDTH + theme.space[2] * 2) * 2;
  const responsiveProp = {
    desktop: {
      breakpoint: { max: Infinity, min: desktopWidth },
      items: 2
    },
    mobile: {
      breakpoint: { max: desktopWidth, min: 0 },
      items: 1
    }
  };

  return (
    <Flex
      sx={{
        borderRadius: 'large',
        bg: 'purple20',
        mx: 3,
        py: 8,
        justifyContent: 'center',
        position: 'relative',

        '&:before, &:after': {
          content: '" "',
          display: 'block',
          position: 'absolute',
          zIndex: 500,
          top: 0,
          bottom: 0,
          width: 1000
        },

        '&:before': {
          right: -1000,
          background:
            'linear-gradient(270deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0))'
        },

        '&:after': {
          left: -1000,
          background:
            'linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0))'
        }
      }}
    >
      <Box sx={{ width: ['90%', desktopWidth], cursor: 'grab' }}>
        <ClassNames>
          {({ css }) => {
            const containerClassName = css(
              themeUiCss({
                overflow: 'visible'
              })(theme)
            );

            return (
              <Carousel
                responsive={responsiveProp}
                containerClass={containerClassName}
                infinite
                arrows={false}
              >
                {reviews.map(review => {
                  return <Review key={review.id} {...review} />;
                })}
              </Carousel>
            );
          }}
        </ClassNames>
      </Box>
    </Flex>
  );
};

export default Reviews;
