/** @jsx jsx */

import { jsx } from 'theme-ui';
import { useSpring, config, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const ANIMATION_WIDTH = 500;
const ANIMATION_HEIGHT = ANIMATION_WIDTH;

const Halftone = () => {
  return (
    <div
      sx={{
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundImage: 'radial-gradient(#7F879E 15%, transparent 15%);',
        backgroundSize: '12px 12px',
        backgroundPosition: 'center',
        maskImage: 'radial-gradient(rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 0) 80%)'
      }}
    />
  );
};

const Background = ({ bgRadius = 170, children }) => {
  const [ref, isInView] = useInView({
    threshold: 0.6,
    triggerOnce: true
  });

  const bgFrom = { transform: 'scale(0.5)', r: bgRadius / 2, opacity: 0 };
  const bgTo = { transform: 'scale(1)', r: bgRadius, opacity: 1 };

  const bgProps = useSpring({
    from: bgFrom,
    to: isInView ? bgTo : bgFrom,
    config: key => (key === 'r' ? config.wobbly : config.default)
  });

  return (
    <div
      ref={ref}
      sx={{
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        height: 'auto',
        pt: '100%',

        '.background, .foreground': {
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0
        }
      }}
    >
      <animated.div
        className="background"
        style={{ opacity: bgProps.opacity, transform: bgProps.transform }}
      >
        <Halftone />
      </animated.div>

      <div className="background">
        <animated.svg viewBox="0 0 500 500">
          <animated.circle
            fill="url(#candy)"
            cx="250"
            cy="250"
            r={bgProps.r}
            opacity={bgProps.opacity}
          />
          <linearGradient id="candy" gradientTransform="rotate(45)">
            <stop stopColor="#FA8BFF" stopOpacity="0.2" />
            <stop offset="0.5" stopColor="#2BD2FF" stopOpacity="0.2" />
            <stop offset="1" stopColor="#2BFF88" stopOpacity="0.1" />
          </linearGradient>
        </animated.svg>
      </div>

      <animated.div className="foreground">
        {children({
          isInView,
          animationWidth: ANIMATION_WIDTH,
          animationHeight: ANIMATION_HEIGHT
        })}
      </animated.div>
    </div>
  );
};

export const withBackground = (AnimationComponent, bgRadius) => {
  return props => {
    return (
      <Background bgRadius={bgRadius}>
        {bgProps => <AnimationComponent {...props} {...bgProps} />}
      </Background>
    );
  };
};

export default Background;
