/** @jsx jsx */

import { jsx } from 'theme-ui';
import { useSpring, animated } from 'react-spring';
import { withBackground } from './Background';
import { toPercent } from './helpers';
import i2cardback from '../../images/i2cardback.png';
import i2cardfront from '../../images/i2cardfront.png';

const WIDTH = 371;
const HEIGHT = 269;

const Cards = ({ isInView, animationWidth, animationHeight }) => {
  const cardFrom = { opacity: 0, y: 20 };
  const cardTo = { opacity: 1, y: 0 };

  const { opacity, y } = useSpring({
    from: cardFrom,
    to: isInView ? cardTo : cardFrom,
    delay: 400
  });

  return (
    <div
      sx={{
        width: toPercent(WIDTH / animationWidth),
        height: toPercent(HEIGHT / animationHeight),
        position: 'relative',
        top: toPercent((animationHeight - HEIGHT) / 2 / animationHeight),
        left: toPercent((animationWidth - WIDTH) / 2 / animationWidth),

        '.cardback, .cardfront': {
          width: toPercent(170 / WIDTH),
          height: toPercent(269 / HEIGHT),
          position: 'absolute',
          top: 0,
          backgroundSize: 'cover',
          backgroundPosition: 'top left',
          transform: 'rotate(20deg)'
        },

        '.cardback': {
          backgroundImage: `url(${i2cardback})`,
          left: 0
        },

        '.cardfront': {
          backgroundImage: `url(${i2cardfront})`,
          right: 0
        }
      }}
    >
      <animated.div
        className="cardback"
        style={{
          opacity,
          transform: y.interpolate(y => `rotate(20deg) translateY(${y}px)`)
        }}
      />

      <animated.div
        className="cardfront"
        style={{
          opacity,
          transform: y.interpolate(y => `rotate(20deg) translateY(${-y}px)`)
        }}
      />
    </div>
  );
};

export default withBackground(Cards, 200);
