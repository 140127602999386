/** @jsx jsx */

import { jsx } from 'theme-ui';
import { useSpring, animated } from 'react-spring';
import { withBackground } from './Background';
import { toPercent } from './helpers';
import i1iphone from '../../images/i1iphone.png';
import i1card from '../../images/i1card.png';

const WIDTH = 361;
const HEIGHT = 480;

const PhoneCard = ({ isInView, animationHeight, animationWidth }) => {
  const from = { opacity: 0, y: 20 };
  const to = { opacity: 1, y: 0 };

  const { opacity, y } = useSpring({
    from,
    to: isInView ? to : from,
    delay: 400
  });

  return (
    <div
      sx={{
        width: toPercent(WIDTH / animationWidth),
        height: toPercent(HEIGHT / animationHeight),
        position: 'relative',
        top: toPercent(10 / animationHeight),
        left: toPercent(70 / animationWidth),

        '.iphone, .card': {
          position: 'absolute',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        },

        '.iphone': {
          width: toPercent(263 / WIDTH),
          height: toPercent(480 / HEIGHT),
          top: 0,
          left: 0,
          backgroundImage: `url(${i1iphone})`,
          backgroundPosition: 'top right'
        },

        '.card': {
          width: toPercent(196 / WIDTH),
          height: toPercent(257 / HEIGHT),
          top: toPercent(128 / HEIGHT),
          right: 0,
          backgroundImage: `url(${i1card})`,
          backgroundPosition: 'top left'
        }
      }}
    >
      <animated.div
        className="iphone"
        style={{
          opacity,
          transform: y.interpolate(y => `translateY(${y}px)`)
        }}
      />

      <animated.div
        className="card"
        style={{
          opacity,
          transform: y.interpolate(y => `translateY(${-y}px)`)
        }}
      />
    </div>
  );
};

export default withBackground(PhoneCard);
