/** @jsx jsx */

import { jsx, Flex } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import analytics from '../lib/analytics';

const STORE_BUTTON_IMAGES = {
  black: {
    appStore: require('../images/appstoreblack.svg'),
    playStore: require('../images/playstoreblack.svg')
  },
  white: {
    appStore: require('../images/appstorewhite.svg'),
    playStore: require('../images/playstorewhite.svg')
  }
};

const StoreButtons = ({ isBrowser, isAndroid, isIos, variant }) => {
  const {
    datoCmsGeneral: { appStoreUrl, playStoreUrl }
  } = useStaticQuery(
    graphql`
      query {
        datoCmsGeneral {
          appStoreUrl
          playStoreUrl
        }
      }
    `
  );

  const { appStore, playStore } = STORE_BUTTON_IMAGES[variant];

  return (
    <Flex
      sx={{
        height: 54,

        'a + a': {
          ml: 3
        }
      }}
    >
      {isBrowser && !isAndroid && (
        <a
          href={appStoreUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => analytics.track('APP_STORE_BUTTON_CLICK')}
        >
          <img alt="Disponible sur l'App Store" src={appStore} />
        </a>
      )}
      {isBrowser && !isIos && (
        <a
          href={playStoreUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => analytics.track('PLAY_STORE_BUTTON_CLICK')}
        >
          <img alt="Disponible sur Google Play" src={playStore} />
        </a>
      )}
    </Flex>
  );
};

export default StoreButtons;
