import each from 'lodash/each';

const SNAP_EVENT_TYPES = {
  PAGE_VIEW: 'PAGE_VIEW',
  JOIN_FORM_SUBMIT: 'SIGN_UP'
};

const PROVIDERS = {
  amplitude: {
    identify(userId) {
      if (!window.amplitude) return;

      return window.amplitude.getInstance().setUserId(userId);
    },

    track(eventType, eventProps) {
      if (!window.amplitude) return;

      return window.amplitude.getInstance().logEvent(eventType, eventProps);
    }
  },
  gtm: {
    identify() {},

    track(eventType, eventProps) {
      if (!window.dataLayer) return;

      return window.dataLayer.push({
        ...eventProps,
        event: eventType
      });
    }
  },
  snap: {
    identify() {},

    track(eventType) {
      if (!window.snaptr) return;

      const snapEventType = SNAP_EVENT_TYPES[eventType];
      if (snapEventType) {
        window.snaptr('track', snapEventType);
      }
    }
  }
};

const identify = userId => {
  each(PROVIDERS, (provider, providerKey) => {
    provider.identify(userId);
  });
};

const track = (eventType, eventProps) => {
  each(PROVIDERS, (provider, providerKey) => {
    provider.track(eventType, eventProps);
  });
};

export default {
  identify,
  track
};
