/** @jsx jsx */

import { jsx } from 'theme-ui';
import { useState, useEffect, useRef } from 'react';
import { useSpring, useSprings, animated } from 'react-spring';
import random from 'lodash/random';
import times from 'lodash/times';
import sample from 'lodash/sample';
import { withBackground } from './Background';
import { toPercent, rotate } from './helpers';
import i5bank from '../../images/i5bank.png';
import i5faces from '../../images/i5faces.png';

const EMOJI = ['💸', '💰', '💵', '💶'];

const FACE_RADIUS = 40;
const MONEY_RADIUS = 18;
const BG_RADIUS = 125;

const center = { x: 250, y: 250 };

export const Referral = ({ isInView, animationWidth, animationHeight }) => {
  const [data, setData] = useState([]);
  const countRef = useRef(0);

  const face1Center = rotate(
    BG_RADIUS + FACE_RADIUS + 20,
    215,
    animationWidth,
    animationHeight
  );
  const face2Center = rotate(
    BG_RADIUS + FACE_RADIUS + 20,
    150,
    animationWidth,
    animationHeight
  );
  const face3Center = rotate(
    BG_RADIUS + FACE_RADIUS + 20,
    10,
    animationWidth,
    animationHeight
  );
  const faces = [face1Center, face2Center, face3Center];

  const makeItRain = () => {
    countRef.current = 0;

    const nextItems = times(random(5, 45), () => {
      const face = sample(faces);
      return {
        emoji: sample(EMOJI),
        from: { ...center, transform: 'scale(0.5)', opacity: 0 },
        to: [
          { ...center, transform: 'scale(1)', opacity: 1 },
          {
            x: face.x + random(-10, 10),
            y: face.y + random(-10, 10),
            transform: 'scale(1)',
            opacity: 1
          },
          { transform: 'scale(0)', opacity: 0 }
        ]
      };
    });

    setData(nextItems);
  };

  useEffect(
    () => makeItRain(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const springs = useSprings(
    data.length,
    data.map(item => {
      return {
        from: item.from,
        to: item.to,
        delay: random(0, 2000),
        onRest: () => {
          countRef.current++;
          if (countRef.current >= data.length) {
            makeItRain();
          }
        }
      };
    })
  );

  const from = { opacity: 0 };
  const to = { opacity: 1 };
  const props = useSpring({
    from,
    to: isInView ? to : from,
    delay: 400
  });

  return (
    <animated.div
      style={props}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',

        '.face, .money': {
          position: 'absolute',
          borderRadius: '100%'
        },

        '.face': {
          background: '#fff',
          boxShadow: 'light',
          backgroundImage: `url(${i5faces})`,
          backgroundSize: '300% 100%',
          backgroundRepeat: 'no-repeat',
          width: toPercent((FACE_RADIUS * 2) / animationWidth),
          height: toPercent((FACE_RADIUS * 2) / animationHeight)
        },

        '.face1': {
          backgroundPosition: 'left center'
        },

        '.face2': {
          backgroundPosition: 'center center'
        },

        '.face3': {
          backgroundPosition: 'right center'
        },

        '.money': {
          textAlign: 'center',
          fontSize: 26,
          width: toPercent((MONEY_RADIUS * 2) / animationWidth),
          height: toPercent((MONEY_RADIUS * 2) / animationHeight),
          zIndex: 100
        }
      }}
    >
      {faces.map((face, i) => {
        return (
          <div
            key={`face-${i}`}
            className={`face face${i + 1}`}
            style={{
              left: toPercent((face.x - FACE_RADIUS) / animationHeight),
              top: toPercent((face.y - FACE_RADIUS) / animationWidth)
            }}
          />
        );
      })}

      {springs.map((props, i) => (
        <animated.div
          key={i}
          className="money"
          style={{
            opacity: props.opacity,
            transform: props.transform,
            left: props.x.interpolate(x => {
              return toPercent((x - MONEY_RADIUS) / animationHeight);
            }),
            top: props.y.interpolate(y => {
              return toPercent((y - MONEY_RADIUS) / animationWidth);
            })
          }}
        >
          {data[i].emoji}
        </animated.div>
      ))}

      <div
        css={{
          width: toPercent(150 / animationWidth),
          height: toPercent(150 / animationHeight),
          position: 'relative',
          top: toPercent(175 / animationHeight),
          left: toPercent(175 / animationWidth),
          backgroundSize: 'cover',
          backgroundPosition: 'top left',
          backgroundImage: `url(${i5bank})`
        }}
      />
    </animated.div>
  );
};

export default withBackground(Referral, 125);
